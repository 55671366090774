/* eslint-disable */
/* eslint-disable consistent-return */
import React, { useState, useEffect, useContext, Suspense, lazy } from "react";
import { Helmet } from "react-helmet-async";
import PropTypes from "prop-types";
import { LinearProgress, Paper } from "@material-ui/core";
import styled from "styled-components/macro";
import ModuleStepper from "./UI/ModuleStepper";
import PrivateHeader from "../../../components/PrivateHeader";
import OnLoadError from "../../../components/UI/OnLoadError";
import SnackbarWrapper from "../../../components/SnackbarWrapper";
import useQuizReview from "../../../swr/hooks/Library/useQuizReview";
import AboutThisPage from "../../../components/AboutThisPage";
import { GlobalState } from "../../../store/GlobalState";
import BottomToolbar from "./UI/BottomToolbar";
import { QUIZSTATES } from "../../../storage/constants";
import LimitsCheck from "./UI/LimitsCheck.js";

const Launch = lazy(() => import("./launch/index.js"));
const Audience = lazy(() => import("./audience/index.js"));
const Content = lazy(() => import("./content/index.js"));
const Questions = lazy(() => import("./questions/index.js"));
const Basics = lazy(() => import("./basics/index.js"));

const MoveUp = styled.div`
  margin-top: -10px;
  margin-bottom: -10px;
`;

const QuizBuilderWrapper = styled(Paper)`
  max-width: 100%;
  min-height: 540px;
  padding: 0px;
  margin-bottom: 100px;
  display: flex;
  flex-direction: column;
  width: 100%;
  box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.07);
`;

const ComponentWrapper = styled.div`
  min-height: 500px;
  display: flex;
  padding: 14px;
  flex: 1,
  flex-direction: row;
  gap: 12px;

  @media (max-width: 1040px) {
    flex-direction: column;
  }
`;

const QuizBuilder = ({ match }) => {
  const [stepIndex, setStepIndex] = useState(0);
  const [videoUploading, setVideoUploading] = useState(false);

  const { state } = useContext(GlobalState);
  const { isRetail, job } = state;

  const [snackbarText, setSnackbarText] = useState("");
  const { details, isLoading, isError, mutate, isValidating } = useQuizReview(
    match.params.id
  );

  useEffect(() => {
    mutate();
  }, [stepIndex]);

  if (isLoading) return null;

  if (isError) {
    return <OnLoadError />;
  }

  // Check to see if our video is currently converting
  const convertingIds = state?.transcoding?.map((quiz) => quiz?.quiz_id);

  const hasVideoConverting =
    convertingIds.includes(details?.id) &&
    details.state === "Content Conversion In Progress";

  const isModuleLive = details?.state === QUIZSTATES.LIVE;
  const isModulePending = details?.state === QUIZSTATES.PENDING;
  const isModuleConvertingNextStateLive =
    details?.state === QUIZSTATES.CONVERSION &&
    details?.next_state_str === QUIZSTATES.LIVE;
  const isModuleConvertingNextStateDev =
    details?.state === QUIZSTATES.CONVERSION &&
    details?.next_state_str === QUIZSTATES.IN_DEVELOPMENT;
  const isModuleConvertingNextStatePending =
    details.state === QUIZSTATES.CONVERSION &&
    details?.next_state_str === QUIZSTATES.PENDING;
  const isModuleInReview = details.state === QUIZSTATES.IN_REVIEW;
  const isModuleConvertingNextStateReview =
    details.state === QUIZSTATES.CONVERSION &&
    details?.next_state_str === QUIZSTATES.IN_REVIEW;

  const isManager = job === "M";

  const retailModuleHasNoAudience =
    details.audience.type === "O" && !details?.audience?.audience_exists;

  const retailSteps = [
    {
      label: "Basics",
      complete: Boolean(
        details.description &&
          details.startDate &&
          details.image &&
          details.name
      ),
      requiredFields: 4,
      completedFields: [
        Boolean(details.description),
        Boolean(details.startDate),
        Boolean(details.image),
        Boolean(details.name),
      ].filter((bool) => bool === true).length,
    },
    {
      label: "Content",
      complete: Boolean(details.media || details.video),
      requiredFields: 1,
      completedFields: Boolean(details.media || details.video) ? 1 : 0,
    },
    {
      label: "Questions",
      complete: details.questions.questions.length > 0,
      requiredFields: 1,
      completedFields: details.questions.questions.length > 0 ? 1 : 0,
    },
    {
      label: "Audience",
      complete: !retailModuleHasNoAudience,
      requiredFields: 1,
      completedFields: !retailModuleHasNoAudience ? 1 : 0,
    },
    {
      label: "Launch",
      complete: [
        Boolean(details.description),
        Boolean(details.startDate),
        Boolean(details.image),
        Boolean(details.name),
        !retailModuleHasNoAudience,
        Boolean(details.questions.questions.length > 0),
        Boolean(details.media || details.video),
      ].every((val) => val === true),
      requiredFields: 7,
      completedFields: null,
    },
  ];

  const vendorSteps = [
    {
      label: "Basics",
      complete: Boolean(
        details.description &&
          details.startDate &&
          details.image &&
          details.name
      ),
      requiredFields: 4,
      completedFields: [
        Boolean(details.description),
        Boolean(details.startDate),
        Boolean(details.image),
        Boolean(details.name),
      ].filter((bool) => bool === true).length,
    },
    {
      label: "Content",
      complete: Boolean(details.media || details.video),
      requiredFields: 1,
      completedFields: Boolean(details.media || details.video) ? 1 : 0,
    },
    {
      label: "Questions",
      complete: details.questions.questions.length > 0,
      requiredFields: 1,
      completedFields: details.questions.questions.length > 0 ? 1 : 0,
    },
    {
      label: "Deploy",
      complete: Boolean(details?.inMarketPlace || details?.isSuggested),
      requiredFields: null,
      completedFields: null,
    },
  ];

  const stepComponents = {
    0: Basics,
    1: Content,
    2: Questions,
    3: Audience,
    4: Launch,
  };

  const vendorStepComponents = {
    0: Basics,
    1: Content,
    2: Questions,
    3: Launch,
  };

  const StepComponent = isRetail
    ? stepComponents[stepIndex]
    : vendorStepComponents[stepIndex];
  const steps = isRetail ? retailSteps : vendorSteps;

  return (
    <>
      <Helmet title="Spiffy | Module Builder" />
      <MoveUp>
        <PrivateHeader header="Module Builder" secondary={details?.name} small>
          <LimitsCheck isRetail={isRetail} />
        </PrivateHeader>
      </MoveUp>
      {!isModuleLive && (
        <AboutThisPage
          pageTitle="Build A Module"
          tipText="Help with building a module"
        />
      )}
      <ModuleStepper
        stepIndex={stepIndex}
        setStepIndex={setStepIndex}
        steps={steps}
        videoUploading={videoUploading}
        setVideoUploading={setVideoUploading}
      />
      <div style={{ height: 8 }} />
      <QuizBuilderWrapper>
        <ComponentWrapper>
          <Suspense fallback={<span />}>
            <StepComponent
              id={match.params.id}
              steps={steps}
              stepIndex={stepIndex}
              setStepIndex={setStepIndex}
              details={details}
              setSnackbarText={setSnackbarText}
              mutate={mutate}
              isRetail={isRetail}
              isManager={isManager}
              hasVideoConverting={hasVideoConverting}
              isModulePending={isModulePending}
              isModuleLive={isModuleLive}
              isModuleConvertingNextStateDev={isModuleConvertingNextStateDev}
              isModuleConvertingNextStateLive={isModuleConvertingNextStateLive}
              isModuleConvertingNextStateReview={
                isModuleConvertingNextStateReview
              }
              isModuleConvertingNextStatePending={
                isModuleConvertingNextStatePending
              }
              isModuleInReview={isModuleInReview}
              videoUploading={videoUploading}
              setVideoUploading={setVideoUploading}
            />
          </Suspense>
        </ComponentWrapper>
        <BottomToolbar
          title={steps[stepIndex]?.label}
          steps={steps}
          stepIndex={stepIndex}
          details={details}
          isModulePending={isModulePending}
          isModuleLive={isModuleLive}
          isModuleConvertingNextStateDev={isModuleConvertingNextStateDev}
          isModuleConvertingNextStateLive={isModuleConvertingNextStateLive}
          isModuleConvertingNextStatePending={
            isModuleConvertingNextStatePending
          }
          isRetail={isRetail}
          isModuleConvertingNextStateReview={isModuleConvertingNextStateReview}
        />
        {isValidating && (
          <LinearProgress
            color="secondary"
            variant="indeterminate"
            style={{
              height: 2,
            }}
          />
        )}
      </QuizBuilderWrapper>
      <SnackbarWrapper
        snackbarText={snackbarText}
        setSnackbarText={setSnackbarText}
      />
    </>
  );
};

export default QuizBuilder;

QuizBuilder.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};
