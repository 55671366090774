/* eslint-disable */
import React, { useContext } from "react";
import styled, { withTheme } from "styled-components/macro";
import PropTypes from "prop-types";
import {
  Grid,
  Hidden,
  AppBar as MuiAppBar,
  IconButton as MuiIconButton,
  Toolbar,
} from "@material-ui/core";
import { Menu as MenuIcon } from "@material-ui/icons";
import ShowTranscode from "../ShowTranscode";
import AccountDropdownV2 from "./AccountDropdownV2";
import { GlobalState } from "../../store/GlobalState";
import CompanyDropdown from "./CompanyDropdown";

const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.header.background};
  color: ${(props) => props.theme.header.color};
`;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

const ConditionalSpacing = styled.div`
  width: 2px;
  height: 34px
  background: transparent;
  margin-left: 12px;


  @media(max-width: 600px) {
    display: none;
  }
`;

const AppBarComponent = ({ onDrawerToggle }) => {
  const { state } = useContext(GlobalState);
  const { transcoding } = state;

  return (
    <>
      <AppBar position="sticky" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center" spacing={4}>
            {/* Initially was mdUP */}
            <Hidden lgUp>
              <Grid item>
                <IconButton
                  color="inherit"
                  aria-label="Open drawer"
                  onClick={onDrawerToggle}
                >
                  <MenuIcon />
                </IconButton>
              </Grid>
            </Hidden>
            <Grid item xs />
            {transcoding && transcoding.length > 0 && (
              <Grid item>
                <div>
                  <ShowTranscode transcoding={transcoding} />
                </div>
              </Grid>
            )}

            <Grid item>
              <CompanyDropdown />
            </Grid>
            <ConditionalSpacing />
            <Grid item>
              <AccountDropdownV2 />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default withTheme(AppBarComponent);

AppBarComponent.propTypes = {
  onDrawerToggle: PropTypes.any.isRequired,
};
